import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import { base_url, client_name, currency, currencycode, helmet_url, postHeader } from '../../../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Loader from '../../../../Components/Loader';
import Heading from '../ui/Heading';

function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <section className="rcs_signup_to_section py-5">
            <Container>
                <Row className='align-items-center '>
                    <Col md={12} className='text-center'>
                        <Heading className='text-center p-0'>
                            <h2>SIGN UP TO BE A FIREROCK DIAMONDS INSIDER</h2>
                            <p className='mb-3'>Become a preferred subscriber and get a special offer on your purchase.</p>
                        </Heading>
                    </Col>
                    <Col md={12}>
                        <div className="rcs_signup_to_input_field">
                            <Form className="gs_news_form" onSubmit={newsletter}>
                                <InputGroup>
                                    <FormControl
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        type="email"
                                        value={newsletteremail}
                                        placeholder='Your email address'
                                        required
                                        onChange={(e) => setNewsletteremail(e.target.value)}
                                    />
                                    <Button className="btn outline-base-btn large" type="submit" id="button-addon2">
                                    Subscribe
                                    </Button>
                                </InputGroup>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={6} xs={12} className="ml-auto pr-0 p-0">
                        
                    </Col>
                </Row>
            </Container>

            <Modal show={loader}>
                <Loader/>
            </Modal>
        </section>
    );
}


export default SignupTo;
